import {
  getCourseDetailRoute,
  getFileDetailRoute,
  getPathDetailRoute,
  getPremiumPathRoute,
  getSkillDetailRoute,
  SHARP_DETAIL_ROUTE,
} from "@src/components/contentLibrary/utils";
import { locationGroupTypeDisplayName } from "@src/components/home/constants";
import { getUserTypeLabel } from "@src/components/people/personDetail/EditUserType";
import {
  getLocationDetailRoute,
  getLocationGroupDetailRoute,
  getPersonDetailRoute,
  getRoleDetailRoute,
  getRoleGroupDetailRoute,
  getTagRoute,
} from "@src/components/people/utils/getDetailRoute";
import {
  CourseOrPathItem,
  EmployeeItem,
  JumpToItem,
  LocationItem,
  OrgConfigItem,
  RoleGroupItem,
  RoleItem,
  SkillItem,
  TagItem,
} from "@src/components/ui/jumpToBar/hooks/useAllJumpToItems";
import { LocationGroupType } from "@src/types.generated";
import { formatMultipleGroupNames } from "@src/utils/strings";
import { Route } from "nextjs-routes";

export const getLinkToItem = (item: JumpToItem): Route => {
  let url = getTagRoute(item.id as string);
  if ("libraryItemId" in item && !item.isSharp && !isOrgConfigItemType(item)) {
    url = {
      pathname: "/library/library-item/[id]",
      query: { id: item.libraryItemId },
    };
  } else if (item.isSharp) {
    url = SHARP_DETAIL_ROUTE;
  } else if (item.type === "Module") {
    const pathId = item.id as number;
    url = getPathDetailRoute(pathId);
  } else if (item.type === "Course") {
    const courseId = item.id as number;
    url = getCourseDetailRoute(courseId);
  } else if (item.type === "Resource") {
    url = getFileDetailRoute(item.id as string);
  } else if (item.type === "Skill") {
    url = getSkillDetailRoute(item.id as number);
  } else if (isEmployeeItemType(item)) {
    url = getPersonDetailRoute(item.id as number);
  } else if (isLocationItemType(item)) {
    url = getLocationDetailRoute(item.id as number);
  } else if (isRoleItemType(item)) {
    url = getRoleDetailRoute(item.id as number);
  } else if (isRoleGroupItemType(item)) {
    url = getRoleGroupDetailRoute(item.id as string);
  } else if (isLocationGroupTypeEnum(item.type)) {
    url = getLocationGroupDetailRoute(item.id as string);
  } else if (isTagItemType(item)) {
    url = getTagRoute(item.id as string);
  } else if (isOrgConfigItemType(item)) {
    url = getPremiumPathRoute(item.catalogPathId);
  }
  return url;
};

export const itemDescription = (item: JumpToItem): string => {
  if (isEmployeeItemType(item)) {
    if (item.deactivatedAt) {
      return `Deactivated ${getUserTypeLabel(item?.userType)}`;
    }
    return `${getUserTypeLabel(item?.userType)} ${
      item?.locations && item.locations.length > 0
        ? ` at ${formatMultipleGroupNames(item.locations)}`
        : ""
    }`;
  } else if (isLocationGroupTypeEnum(item.type)) {
    return locationGroupTypeDisplayName[item.type];
  } else if (isLocationItemType(item)) {
    return "Location";
  } else if (isRoleItemType(item)) {
    return "Role";
  } else if (isRoleGroupItemType(item)) {
    return "Department";
  } else if (isOrgConfigItemType(item)) {
    return `Premium Module`;
  } else {
    if (item.type === "Skill") {
      return "Check-in";
    }
    return item.type;
  }
};

export function isContentItemType(
  item: JumpToItem,
): item is CourseOrPathItem | OrgConfigItem | SkillItem {
  return ["Course", "PremiumPath", "Module", "Skill", "Resource"].includes(
    item.type,
  );
}

export function isEmployeeItemType(item: JumpToItem): item is EmployeeItem {
  return item.type === "Employee";
}

export function isLocationItemType(item: JumpToItem): item is LocationItem {
  return item.type === "Location";
}

export function isRoleItemType(item: JumpToItem): item is RoleItem {
  return item.type === "Role";
}

export function isRoleGroupItemType(item: JumpToItem): item is RoleGroupItem {
  return item.type === "RoleGroup";
}

export function isTagItemType(item: JumpToItem): item is TagItem {
  return item.type === "Tag";
}

export function isOrgConfigItemType(item: JumpToItem): item is OrgConfigItem {
  return item.type === "PremiumPath";
}

export const isLocationGroupTypeEnum = (
  type: string,
): type is LocationGroupType => {
  return Object.values(LocationGroupType).includes(type as LocationGroupType);
};

export const getFirstAndLastInitials = (name?: string): string => {
  if (!name) return "??";
  const parts = name.trim().split(" ");
  if (parts.length === 0) return "??";
  const firstInitial = parts[0][0].toUpperCase();
  const lastInitial =
    parts.length > 1 ? parts[parts.length - 1][0].toUpperCase() : "";
  return lastInitial ? `${firstInitial}${lastInitial}` : firstInitial;
};

export const getFirstNameAndLastInitial = (name?: string): string => {
  if (!name) return "";
  const parts = name.trim().split(" ");
  if (parts.length === 0) return "";
  const firstName = parts[0];
  const lastInitial =
    parts.length > 1 ? parts[parts.length - 1][0].toUpperCase() : "";
  return lastInitial ? `${firstName} ${lastInitial}.` : firstName;
};
